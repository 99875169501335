<template>
  <footer class="footer-block">
    <div class="container">
      <div class="header">
        <a href="/" class="logo">

        </a>
        <nav class="d-flex align-items-center">
          <router-link to="/" class="header-item" :class="{ 'header-item-active' : activeLink === '/'}">
            Главная
          </router-link>
          <router-link to="/services" class="header-item" :class="{ 'header-item-active' : activeLink === '/services'}">
            Услуги
          </router-link>
          <router-link to="/about" class="header-item" :class="{ 'header-item-active' : activeLink === '/about'}">
            О компании
          </router-link>
          <router-link to="/portfolio" class="header-item" :class="{ 'header-item-active' : activeLink === '/portfolio'}">
            Портфолио
          </router-link>
          <router-link to="/products" class="header-item" :class="{ 'header-item-active' : activeLink === '/products'}">
            Продукты
          </router-link>
          <router-link to="/partnership" class="header-item" :class="{ 'header-item-active' : activeLink === '/partnership'}">
            Партнерство
          </router-link>
          <router-link to="/blog" class="header-item" :class="{ 'header-item-active' : activeLink === '/blog'}">
            Блог
          </router-link>
          <router-link to="/contacts" class="header-item" :class="{ 'header-item-active' : activeLink === '/contacts'}">
            Контакты
          </router-link>
        </nav>
        <div class="d-flex">
          <div class="dropdown">
            <a class="current-lang">RU</a>
            <ul class="dropdown-langs">
              <li>
                RU
              </li>
              <li>
                EN
              </li>
              <li>
                KZ
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="contact-footer">
        <a class="contact-telegram">
          +7 706 708 0424
        </a>
        <a class="contact-email">
          it@ts-technology.kz
        </a>
      </div>
      <div class="copy-right">
        © {{date}} Troubleshooting. Все права защищены.
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      date: new Date().getFullYear(),
      activeLink: '/',
    }
  },
  mounted() {
    this.activeLink = this.$route.path
  },
  watch: {
    '$route'(to, from) {
      this.activeLink = to.path
    }

  },
}
</script>

<style>

</style>
