import Empty from '@/views/Empty'
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'

export default [
    {
        path: '/',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Home/Index')
        },
    },
    {
        path: '/services',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Home/Services')
        },
    },
    {
        path: '/about',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Home/About')
        },
    },
    {
        path: '/portfolio',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Home/Portfolio')
        },
    },
    {
        path: '/products',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Home/Products')
        },
    },
    {
        path: '/products/djoon',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Products/ProductDjoon')
        },
    },
    {
        path: '/products/rentmate',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Products/ProductRent')
        },
    },
    {
        path: '/products/registry',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Products/ProductRegistry.vue')
        },
    },
    {
        path: '/products/mynbala',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Products/ProductMynBala.vue')
        },
    },
    {
        path: '/products/birgeoqy',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Products/ProductBirgeOqy.vue')
        },
    },
    {
        path: '/products/academy',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Products/ProductAcademy.vue')
        },
    },
    {
        path: '/products/smartustaz',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Products/ProductSmartustaz.vue')
        },
    },
    {
        path: '/products/dashboard',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Products/ProductDashboard.vue')
        },
    },
    {
        path: '/products/proctoring',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Products/ProductProctoring.vue')
        },
    },
    {
        path: '/products/aerc',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Products/ProductAerc.vue')
        },
    },
    {
        path: '/products/qabilet',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Products/ProductQabilet.vue')
        },
    },
    {
        path: '/products/texme',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Products/ProductTexme.vue')
        },
    },
    {
        path: '/products/gastreat',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Products/ProductGastreat.vue')
        },
    },
    {
        path: '/partnership',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Home/Partnership')
        },
    },
    {
        path: '/contacts',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Home/Contacts')
        },
    },
    {
        path: '/blog',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Home/Blog')
        },
    },
    {
        path: '/blog/:id',
        components: {
            header: Navbar,
            footer: Footer,
            default: () => import('@/views/Home/Article')
        },
    },
    {
        path: '*',
        components: {
            default: () => import('@/views/Empty')
        },
    },
]


