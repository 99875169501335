<template>
  <div class="container">
    <div class="header">
      <router-link to="/" class="logo">

      </router-link>
      <nav class="d-flex align-items-center">
        <router-link to="/" class="header-item" :class="{ 'header-item-active' : activeLink === '/'}">
          {{$t('main')}}
        </router-link>
        <router-link to="/services" class="header-item" :class="{ 'header-item-active' : activeLink === '/services'}">
          {{$t('services')}}
        </router-link>
        <router-link to="/about" class="header-item" :class="{ 'header-item-active' : activeLink === '/about'}">
          {{$t('about')}}
        </router-link>
        <router-link to="/portfolio" class="header-item" :class="{ 'header-item-active' : activeLink === '/portfolio'}">
          {{$t('portfolio')}}
        </router-link>
        <div class="products-nav" :class="{ 'header-item-active' : activeLink === '/products'}">
          {{$t('products')}}
          <ul class="products-list">
            <li>
              <router-link to="/products/djoon">
                DJOON
              </router-link>
            </li>
            <li>
              <router-link to="/products/rentmate">
                Rentmate
              </router-link>
            </li>
          </ul>
        </div>
        <router-link to="/partnership" class="header-item" :class="{ 'header-item-active' : activeLink === '/partnership'}">
          {{$t('partnership')}}
        </router-link>
        <router-link to="/blog" class="header-item" :class="{ 'header-item-active' : activeLink === '/blog'}">
          {{$t('blog')}}
        </router-link>
        <router-link to="/contacts" class="header-item" :class="{ 'header-item-active' : activeLink === '/contacts'}">
          {{$t('contacts')}}
        </router-link>
      </nav>
      <div class="d-flex">
        <div class="dropdown">
          <div class="current-lang">{{this.$i18n.locale}}</div>
          <ul class="dropdown-langs">
            <li @click="switchLocale('ru')">
              RU
            </li>
            <li @click="switchLocale('en')">
              EN
            </li>
            <li @click="switchLocale('kz')">
              KZ
            </li>
          </ul>
        </div>
        <div class="write-us">
          Написать нам
        </div>
      </div>
    </div>
    <div class="header-mobile">
      <div class="d-flex align-items-center">
        <router-link to="/" class="logo"/>
        <div class="dropdown">
          <div class="current-lang">{{this.$i18n.locale}}</div>
          <ul class="dropdown-langs">
            <li @click="switchLocale('ru')">
              RU
            </li>
            <li @click="switchLocale('en')">
              EN
            </li>
            <li @click="switchLocale('kz')">
              KZ
            </li>
          </ul>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="write-us">
          Написать нам
        </div>
        <div class="burger-menu-btn" @click="toggleMenu">

        </div>
        <div :class="{ 'menu-open': isOpen }" class="burger-menu">
          <div class="close-menu-btn" @click="isOpen = false">×</div>
          <div class="round-right"></div>
          <div class="round-left"></div>
          <nav class="d-flex flex-column">
            <div class="header-item" @click="goToPage('/')" :class="{ 'header-item-mobile-active' : activeLink === '/'}">
              {{$t('main')}}
            </div>
            <div class="header-item" @click="goToPage('/services')" :class="{ 'header-item-mobile-active' : activeLink === '/services'}">
              {{$t('services')}}
            </div>
            <div class="header-item" @click="goToPage('/about')" :class="{ 'header-item-mobile-active' : activeLink === '/about'}">
              {{$t('about')}}
            </div>
            <div class="header-item" @click="goToPage('/portfolio')" :class="{ 'header-item-mobile-active' : activeLink === '/portfolio'}">
              {{$t('portfolio')}}
            </div>
            <div class="products-nav" @click="showProductsMobileList = !showProductsMobileList">
              {{$t('products')}}<i class="el-icon-arrow-down"></i>
              <div class="products-list-mobile" v-if="showProductsMobileList">
                <div @click="goToPage('/products/djoon')">
                  DJOON
                </div>
                <div @click="goToPage('/products/rentmate')">
                  Rentmate
                </div>
              </div>
            </div>
            <div @click="goToPage('/partnership')" class="header-item" :class="{ 'header-item-active' : activeLink === '/partnership'}">
              {{$t('partnership')}}
            </div>
            <div @click="goToPage('/blog')" class="header-item" :class="{ 'header-item-active' : activeLink === '/blog'}">
              {{$t('blog')}}
            </div>
            <div @click="goToPage('/contacts')" class="header-item" :class="{ 'header-item-active' : activeLink === '/contacts'}">
              {{$t('contacts')}}
            </div>
          </nav>
        </div>

      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Navbar',
  data() {
    return {
      activeLink: '/',
      locale: localStorage.getItem('locale-ts'),
      isOpen: false,
      showProductsMobileList: false,
    }
  },
  methods: {
    switchLocale(lang) {
      localStorage.setItem('locale-ts', lang);
      this.$i18n.locale = lang;
      location.reload();
    },
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    goToPage(link) {
      this.isOpen = false
      this.$router.push(link)
    }
  },
  mounted() {
    if (this.$route.path.includes('products')) {
      return this.activeLink = '/products'
    } else {
      this.activeLink = this.$route.path
    }

    if (localStorage.getItem('locale-ts')) {
      this.$i18n.locale = localStorage.getItem('locale-ts');
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.path.includes('products')) {
        return this.activeLink = '/products'
      }
      this.activeLink = to.path
    }

  },
}
</script>

<style>





</style>


